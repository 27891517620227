body {
    text-decoration: none;
}

h1 {
    font-size: 1.5em;
}

form {
    text-align: left;
}

input {
    border-radius: 2px !important;
}

.page-header {
    margin-bottom: 20px;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0 !important;
}

.logo {
    padding: 10px 0;
    background: #001529;
    text-align: center;
    font-size: 2em;
    color: #222 !important;
    border-bottom: 1px solid #d0d0d0; /*border*/
    margin-bottom: 25px;
    cursor: pointer;
}

.logo_login {
    padding: 0;
    background: #fff;
    text-align: center;
    font-size: 3em;
    color: #0367a4;
    margin-bottom: 5px;
    cursor: pointer;
}

.logo_login_container {
    text-align: center;
}

.loading-page {
    text-align: center;
    padding: 30px 50px;
    margin: 20px 0;
}

.ant-layout.site-layout {
    background: #fff;
}

.ant-layout-footer {
    padding: 13px 10px !important;
}

.login-form-container {
    padding-top: 10%;
}

.ant-layout-sider {
    z-index: 1;
}

.ant-layout-sider.ant-layout-sider-collapsed {
    /*flex: 0 0 50px !important;*/
    /*max-width: 50px !important;*/
    /*min-width: 50px !important;*/
    /*width: 50px !important;*/
}

.ant-menu-inline-collapsed {
    /*width: 50px !important;*/
}

.ant-layout-sider,
.ant-layout-sider .ant-layout-sider-trigger {
    /*flex: 0 0 219px !important;*/
    /*max-width: 219px !important;*/
    /*min-width: 219px !important;*/
    /*width: 219px !important;*/
    border-right: 1px solid #d0d0d0; /*border*/
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
    /*width: 50px !important;*/
    /*min-width: 50px !important;*/
}

.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
    width: 79px !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(0, 0, 0, 0.65) !important;;
}

.logo,
.ant-layout-sider,
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(0, 0, 0, 0.65) !important;;
    background: #ececec !important;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    color: white !important;
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #919191 !important;
    color: #eaeaea;
}

.ant-drawer-content-wrapper {
    min-height: 300px;
}

.ant-menu-dark .ant-menu-item:hover {
    background-color: #e2e2e2 !important;
}

.ant-layout-sider-trigger {
    color: #000 !important;
    background: #d7d7d7 !important;
}

/*table*/
.ant-table-thead > tr > th {
    background: rgb(249, 249, 249) !important;
    border-bottom: 1px solid #e3e3e3 !important; /*border*/
}

.ant-table-expanded-row-fixed {
    width: 100% !important;
}

.ant-table-expanded-row-fixed:after {
    border: none !important;
}

.ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid #e3e3e3 !important; /*border*/
}

/*table end*/

/*content*/
.content-block-gray {
    background: rgb(249, 249, 249);
    border: 1px solid #e3e3e3; /*border*/
    padding: 15px 20px;
    min-height: 58px;
}

.site-page-title {
    margin: 0;
    padding: 5px 0px;
    font-size: 1.1em;
    font-weight: bold;
}

.site-base-content-container {
    min-height: 80vh;
}

/*content end*/

.site-layout-background {
    padding: 0 24px 24px 24px !important;
    z-index: 1;
}

.application-header-title {
    margin: 10px 0;
    position: relative;
    min-height: 66px;
}

hr {
    border: 0.01em solid #eaeaea;
}

.not-found-error-page {
    color: red;
}

.header-actions-container {
    text-align: right;
    padding: 2px 0 0 0;
}

.remove-recipe-ingredient-bt.ant-btn-ghost,
.header-action-btn {
    margin-right: 10px;
    border: none !important;
}

.header-action-btn .anticon {
    font-size: 1.3em;
}

.header-action-btn.create {
    color: green;
}

.header-action-btn.view {
    color: darkblue;
}

.header-action-btn.update {
    color: coral;
}

.header-action-btn.delete {
    color: orangered;
}

.header-action-btn.index {
    color: #0367a4;
}

.ant-upload-list-item-name {
    color: #006efb;
    cursor: pointer;
}

.ant-form-item-label label {
    font-weight: bold
}

.layout-loader {
    top: 0;
    font-size: 0 !important;
    line-height: 0 !important;
    position: fixed !important;
    left: 0;
}

.layout-loader .ant-progress-inner,
.layout-loader .ant-progress-bg {
    height: 1px !important;
    border-radius: 0 !important;
}

.btn_select_addon {
    position: relative !important;
    bottom: -40px !important;
    float: right !important;
}